import { render, staticRenderFns } from "./Student..Courses.vue?vue&type=template&id=a2780686"
import script from "./Student..Courses.vue?vue&type=script&lang=js"
export * from "./Student..Courses.vue?vue&type=script&lang=js"
import style0 from "./Student..Courses.vue?vue&type=style&index=0&id=a2780686&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports