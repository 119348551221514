<template>
  <div>
    <div class="card bg-transparent border-0">
      <div class="card-body">
        <!-- fix top -->
        <!-- <div class="d-block">
        <div class="text-center ml-5 mr-5 bg-darkblue rounded-pill">
          
        </div>
      </div> -->
        <div class="p-2 custom-bg shadow-sm border rounded">
          <div class="d-block">
            <div class="text-center">
              <h5 class=" text-center font-weight-bolder m-0"
                >Registered Courses</h5
              >
            </div>
            <hr class="w-100 m-0 border-2 bg-warning"/>
          </div>

          <div class="row">
            <div class="col">
              <b class="m-0 p-0">Course Title</b>
            </div>
            <div class="col-2">
              <b class="m-0 p-0 text-center">Course Code</b>
            </div>
            <div class="col-2">
              <b class="m-0 p-0 float-right">Units</b>
            </div>
          </div>
        </div>
        <div class="overflow-auto">
          <div class="my-2 mx-1" style="height: 200px">
            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>

            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>

            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>

            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>

            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>

            <div class="shadow p-2 mb-2 border rounded border-1">
              <div class="row">
                <div class="col">
                  <label class="m-0 p-0">Linaer Algebra</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 text-center">MAT101</label>
                </div>
                <div class="col-2">
                  <label class="m-0 p-0 float-right">3</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg{
    
}
</style>
